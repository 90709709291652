<template>
  <div>
    <section class="main no-flex">
      <div
        class=""
      >
        <div v-if="stateType == 'High'">

          <PassiveStockDashboardVS />
        </div>
        <!-- <div v-if="client == 'FjollaNila'">
          <PassiveStockDashboardFN />
        </div> -->
      </div>
    </section>

  </div></template>

<script>
// import OrdersListt from '@/components/productsInStock/ProductsListInStock.vue'
import { mapGetters } from 'vuex';
import PassiveStockDashboardVS from '@/components/productsInStock/PassiveStockDashboardVS.vue'
// import PassiveStockDashboardFN from '../components/PassiveStockDashboardFN.vue'
import { client } from '../domainConfig'
// import ArticleListingg from '@/components/productsInStock/AssignTeamInProductList.vue'

export default {
  name: 'Home',
  components: {
    // ArticleListingg,
    PassiveStockDashboardVS,
    // PassiveStockDashboardFN,
  },
  data() {
    return {
      client: client.clientName,
      stateType: client.stateType,
      orderNumber: '',
      productMatObj: {},
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  methods: {
    inside(item) {
      this.productMatObj = item
    },
    getON(id) {
      this.orderNumber = id
    },
  },
}
</script>

<style scoped lang="scss">
 @media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
    justify-content: center;
  }
 }
 @media screen and (max-width: 780px) {
  .main {
    .flex {
      flex-direction: column;
      .color-listing__wrapper {
        margin-left: 0;
      }
    }
  }
 }
</style>
