<template>
  <section
    class="main"
    style="padding: 0px"
  >
    <div class="container">
      <h5
        class="main__title"
        style="margin-bottom: 40px; font-size: 20px; font-weight: 500; color: #626F86;"
      >
        {{ $t('StockDashboard') }}
      </h5>

      <div
        class="light-card__wrapper"
      >
        <div
          v-for="(item, key) in getProductInStockDashboard"
          :key="key"
          v-b-tooltip.hover
          class="light-card"
          :title="getLanguage == 'en' ? `${$t('ClickFor')} ${$t(item.productInStockState)} ${$t('Details')}` : `${$t('ClickForDetails')} ${$t(item.productInStockState)}`"
          @click="resetOrdersOverview()"
        >
          <div
            :disabled="getLoggedInUser.role == 'Sewer' && item.productInStockState == 'Created'"
            :event="getLoggedInUser.role == 'Sewer' && item.productInStockState == 'Created' ? '' : 'click'"
            style="
              text-decoration: none;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
            "
            @click="goToArticles(item.productInStockState)"
          >
            <!-- <span
              style="text-align: center;"
              class="light-card__number"
              :class="{
                blue: item.productInStockState === 'Confirmed' || item.productInStockState === 'Created' || item.productInStockState === 'Assigned',
                green: item.productInStockState === 'InProduction' || item.productInStockState === 'InSewing' || item.productInStockState === 'QualityPassed' || item.productInStockState === 'ReadyToDeliver' || item.productInStockState === 'Delivered',
                red: item.productInStockState === 'StockNotAvailable' || item.productInStockState === 'QualityCheckNotPassed',
              }"
            >{{ key + 1 }}</span> -->
            <img
              :src="require(`@/assets/images/ValdrinSahiti/DashboardPassive/Logo-${getLoggedInUser.role == 'TeamLeader'? key+=2 : getLoggedInUser.role == 'Deliverer'? key+=7 : key+1}.svg`)"
              alt=""
              style="margin-bottom: 10px;"
            >
            <h6 style="margin-bottom: 20px;">
              {{ $t(`${item.productInStockState}`) }}
            </h6>
            <h4 style="color: #FF274F; font-weight: 700; font-size: 20px;">
              {{ item.count }}
            </h4>
          </div>
        </div>
      </div>

      <!-- <div style="width:100%; display:flex; justify-content: center; ">
        <img
          src="../assets/images/logodashboard.png"
          alt="logo blue"
          style="width: 60%; height: 100%;margin-top: 150px;"
        >
      </div> -->
      <!-- <div class="main__footer">
        <div class="main__notifications">
          <h4>Notifications</h4>
          <h5 class="text-blue">
            - 24 new products created!
          </h5>
          <h5 class="text-red">
            - 3 products returned!
          </h5>
          <h5>- You have 13 products assigned to you!</h5>
          <h5>- Team Blue is 3rd on list, you are 30 points behind team Green</h5>
        </div>
        <div
          class="main__feedback"
          :class="colorByRate"
        >
          <span>Team Rating</span>
          <h1>{{ rating }}</h1>
        </div>
        <div class="main__calendar">
          <h3>Calendar coming soon!</h3>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      loading: false,
      orderGroup: {},
      rating: 7,
    };
  },
  computed: {
    ...mapGetters(['getLanguage', 'getLoggedInUser', 'getIsLoading', 'getProductInStockDashboard']),
    colorByRate() {
      if (this.rating <= 3) {
        return 'red';
      }
      if (this.rating > 3 && this.rating <= 6) {
        return 'orange';
      }
      return 'green';
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['loadOrdersGrouppedByState', 'changeLoadingtoTrue', 'resetOrdersOverview', 'productInStock_cockpit_overview']),
    goToArticles(value) {
      console.log(value)
      this.$router.push({
        path: '/products-in-stock',
        query: {
          state: value,
        },
      })
      // :to="getLoggedInUser.role == 'Deliverer' ? '/deliverer-orders-management/' + item.productInStockState : 'products-in-stock/' + item.productInStockState"
    },

    async fetch() {
      // this.changeLoadingtoTrue(false)

      try {
        await this.productInStock_cockpit_overview({
          assingedTeamNameId: null,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
  },


};
</script>

<style lang="scss" scoped>

.container {
  width: 100% !important;
}

.light-card__wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  border: 1px solid #DDE0E5;
}

.light-card:hover{
  transform: none;
  z-index: 100;
}

.light-card {
  max-width: 100%;
  height: 190px;
}
 .hide {
  display: none;
 }

 .light-card {
        overflow-wrap: anywhere;
        border: 1px solid #DDE0E5;
        padding: 40px;
  }

  @media (min-width: 1400px){
  .container{
    max-width: 100%;
  }
}

  @media screen and (max-width: 1600px){
    .main{
      padding: 10px 15px 15px 0px !important;
    }
  }
 @media screen and (max-width: 1190px) {
  .main {
    padding: 10px 0 0 0 !important;
    .container {
      margin-right: 0;
      margin-left: 0;
      max-width: inherit;
    }
  }
  }

 @media screen and (max-width: 781px) {
  .main {
    .container {
      .light-card__wrapper {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
      }
      .light-card {
        overflow-wrap: anywhere;
      }
    }
  }
}
</style>
